import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home';
import { ManageClients } from './components/ManageClients';
import ProjectPage from './components/ProjectPage';
import MainEditor from './components/Editor/MainEditor';
import RoomEditorPage from './components/RoomEditor/RoomEditorPage';
import EmailSendPage from './components/AdminPanel/EmailSendPage'

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './scss/custom.scss'
import './custom.css'
import ShowAuthorized from './components/api-authorization/ShowAuthorized';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Switch>
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <AuthorizeRoute>
                    <Layout>
                        <AuthorizeRoute exact path='/' component={ProjectPage} />
                        <AuthorizeRoute path='/projects/:id' component={MainEditor} />
                        <ShowAuthorized role="Admin">
                            <AuthorizeRoute path='/manage-clients' component={ManageClients} />         
                            <AuthorizeRoute path='/room' component={RoomEditorPage} />
                            <AuthorizeRoute path='/email' component={EmailSendPage} />
                        </ShowAuthorized>
                    </Layout>
                </AuthorizeRoute>
            </Switch>
        );
    }
}
