import React from 'react';
import { Button, Nav, NavItem, NavLink, Row, Col, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import editorDataService from '../api-data/EditorDataService'
import commonDataService from '../api-data/CommonDataService'
import { GetApiRequest } from '../api-data/ApiRequestService';

import ResourcePage from './ResourcePage';
import CheckPage from './CheckPage';
import RoomsPage from './RoomsPage';

function ResourceGroupTabs(props) {
    let { path, url } = useRouteMatch();
    let match = useRouteMatch(`${path}/:tabId`)
    let currentGroupId = match ? match.params["tabId"] : null;

    let history = useHistory();

    async function saveGroupInRoom(roomId, value) {
        const groupInRoom = {roomId: roomId}
        var res = await editorDataService.setGroupInRoom(props.group.id, groupInRoom, value)
        if (res) {
            let group = props.group;
            if (value) {
                group.rooms.push(groupInRoom)
            }
            else {
                group.rooms = group.rooms.filter(x => x.roomId !== roomId)
            }
            props.saveData(group);
        }
        else {
            console.error("Error saving GroupInRoom");
        }
    }

    //resources and checks
    async function saveCommonData(controllerPath, dataName, data, useFormData = false, formData = null) {
        const isNew = !data.id;
        data.groupId = props.group.id;
        var res = null;

        res = await commonDataService.saveEntry(controllerPath, data.id, data, isNew);

        if (res) {

            let newData = isNew ? res : data;

            if (useFormData) {
                //Upload file:

                //Get current checkId

                let id = null;
                if (isNew) {
                    id = res.id;
                }
                else {
                    id = data.id;
                }

                //Add file
                if (formData.has("file")) {
                    const response = await GetApiRequest(`api/${controllerPath}/${id}/file`, "POST", true, formData, false);
                    if (response.ok) {
                        newData.fileName = formData.get("file").name;
                    }
                    else {
                        history.push(`${url}/${controllerPath}/${props.group.id}`);
                        return;
                    }
                }
                //Delete file
                else {
                    const response = await GetApiRequest(`api/${controllerPath}/${id}/file`, "DELETE", true);
                    if (response.ok) {
                        newData.fileName = null;
                    }
                    else {
                        history.push(`${url}/${controllerPath}/${props.group.id}`);
                        return;
                    }
                }
            }
            let group = props.group;
            if (isNew) {
                group[dataName] = [...group[dataName], newData];
            }
            else {
                group[dataName] = group[dataName].map(x => {
                    if (x.id == data.id)
                        return newData;
                    return x;
                })
            }
            props.saveData(group);


        }

        history.push(`${url}/${controllerPath}/${props.group.id}`);
    }
    async function deleteCommonData(controllerPath, dataName, dataId) {
        var res = await commonDataService.deleteEntry(controllerPath, dataId);
        if (res) {
            let group = props.group;
            group[dataName] = group[dataName].filter(x => x.id != dataId);
            props.saveData(group);
        }
    }

    return (
        <div className="mt-2">
            <Nav tabs>
                <TabItem id="resource" currentId={currentGroupId} onSelect={props.onSelectTab}>
                    Betriebsmittel
                </TabItem>
                <TabItem id="check" currentId={currentGroupId} onSelect={props.onSelectTab}>
                    Checks
                </TabItem>
                {/*<TabItem id="room" currentId={currentGroupId} onSelect={props.onSelectTab}>
                    Raumverteilung
                </TabItem>*/}
            </Nav>
            <Switch>
                <Route path={`${path}/resource/:groupId`}>
                    <ResourcePage
                        resources={props.group.resources}
                        saveResource={(data) => saveCommonData("resource", "resources", data)}
                        deleteResource={(id) => deleteCommonData("resource", "resources", id)}
                        isReadonly={props.isReadonly}
                        filterTypes={props.group.filters}
                    />
                </Route>
                <Route path={`${path}/check/:groupId`}>
                    <CheckPage
                        checks={props.group.checks}
                        saveCheck={(jsonData, formDataChanged, formData) => saveCommonData("check", "checks", jsonData, formDataChanged, formData)}
                        deleteCheck={(id) => deleteCommonData("check", "checks", id)}
                        isReadonly={props.isReadonly}
                    />
                </Route>
                {/*<Route path={`${path}/room/:groupId`}>
                    <RoomsPage
                        roomBaseData={props.roomData}
                        rooms={props.group.rooms}
                        setRoom={saveGroupInRoom}
                        isReadonly={props.isReadonly}
                    />
                </Route>*/}
                <Redirect to={`${path}`} />
            </Switch>
        </div>
    );
}
function TabItem(props) {
    return (
        <NavItem>
            <NavLink
                active={props.currentId === props.id}
                onClick={() => props.onSelect(props.id)}
            >
                {props.children}
            </NavLink>
        </NavItem>
    );
}

export default ResourceGroupTabs;
