import React from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import FormTextInput from './FormTextInput';

export default function ProjectEditor(props) {
    let name = "";
    let description = "";
    if (props.project) {
        name = props.project.name;
        description = props.project.description;
    }

    return (
        <Formik
            initialValues={{ name: name, description: description }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, 'Projektname muss mindestens 3 Zeichen lang sein!')
                    .max(30, 'Projektname muss 30 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                description: Yup.string()
                    .max(300, 'Projektbeschreibung muss 300 Zeichen oder kürzer sein!'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                props.onClickSave({ name: values.name, description: values.description });            
            }}
        >
            <Form>
                <h3>{name ? "Projekt bearbeiten" : "Neues Projekt"}</h3>
                <div className="form-group">
                    <FormTextInput
                        label="Projektname"
                        name="name"
                        type="text"
                        placeholder="Projektname"
                    />
                </div>
                <div className="form-group">
                    <FormTextInput
                        label="Beschreibung"
                        name="description"
                        type="text"
                        placeholder="Beschreibung"
                    />
                </div>
                <div className="btn-toolbar">
                    <div className="btn-group mr-2">
                        <button type="submit" className="btn btn-primary">Speichern</button>
                    </div>
                    <div className="btn-group mr-2">
                        <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}