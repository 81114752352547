import React, { useState } from 'react';
import DeleteModal from './DeleteModal';
import UserEditor from './UserEditor';
import { CardBody, Card } from 'reactstrap';

export default function UserList(props) {

    const [currUser, setCurrUser] = useState(null);
    const [editMode, setEditMode] = useState("None");

    function onDelete() {
        if (currUser) {
            console.log(`Delete User: ${currUser}`)
            props.onClickDelete(currUser);
            setCurrUser(null);
        }
    }
    function clickDelete(id, e) {
        console.log(`Delete User: ${id}`)
        setCurrUser(id);
        setEditMode("Delete");
    }
    function clickEdit(id, e) {
        console.log(`Edit User: ${id}`)
        setCurrUser(id)
        setEditMode("Edit");
    }
    function clickNew(e) {
        console.log('Add User')
        setCurrUser(null);
        setEditMode("Edit");
    }
    function onEditorSave(user) {
        console.log('Save User')
        user.id = currUser;
        props.onClickSave(user)
        setEditMode("none");
        setCurrUser(null);
    }
    function onCancel() {
        setEditMode("None");
        setCurrUser(null);
    }
    const currUserData = props.users.find(x => x.id == currUser);

    return (
        <div>
            <DeleteModal header="Benutzer löschen" label={"Wollen Sie den Benutzer " + (currUserData ? currUserData.firstName + " " + currUserData.lastName : "") + " wirklich löschen?"} onClick={onDelete} onCancel={onCancel} show={editMode == "Delete"} />
            {editMode == "Edit" ?
                <UserEditor user={currUserData} onClickSave={onEditorSave} onClickCancel={onCancel} />
                :
                <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Vorname</th>
                            <th>Nachname</th>
                            <th>Email</th>
                            <th>Rolle</th>
                            <th>Deaktiviert</th>
                            <th>
                                <div className="row justify-content-end">
                                    <div className="col-md-4 col-md-offset-1">
                                        <button type="button" onClick={clickNew} className="btn btn-success">Neuer Benutzer</button>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.users.map(user =>
                            <tr key={user.id}>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.role ? user.role : "Keine Rolle"}</td>
                                <td>{user.lockoutEnabled ? "Ja" : "Nein"}</td>
                                <td>
                                    <div className="btn-toolbar float-right" >
                                        <div className="btn-group mr-2">
                                            <button type="button" onClick={() => clickEdit(user.id)} className="btn btn-primary">Bearbeiten</button>
                                        </div>
                                        <div className="btn-group mr-2">
                                            <button type="button" onClick={() => clickDelete(user.id)} className="btn btn-danger">Löschen</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </div>
    );
}