import React, { Component } from 'react';
import adminPanelService from './api-data/AdminPanelService'
import ClientHeader from './ClientHeader';
import ClientEditor from './ClientEditor';
import { Spinner, Card, CardBody } from 'reactstrap';
import {
    Route,
    Switch
} from 'react-router-dom';
import { matchPath } from 'react-router';
import UserList from './UserList';

export class ManageClients extends Component {
    static displayName = ManageClients.name;

    constructor(props) {
        super(props);
        this.state = { clients: [], currentClientId: null, loading: true, isEditMode: false };
        this.handleClientSelect = this.handleClientSelect.bind(this);
        this.handleOnEditClient = this.handleOnEditClient.bind(this);
        this.handleOnClientSave = this.handleOnClientSave.bind(this);
        this.handleOnClientEditCancel = this.handleOnClientEditCancel.bind(this);
        this.handleOnDeleteClient = this.handleOnDeleteClient.bind(this);

        this.handleOnDeleteUser = this.handleOnDeleteUser.bind(this);
        this.handleOnSaveUser = this.handleOnSaveUser.bind(this);

        this.renderInner = this.renderInner.bind(this);
    }

    componentDidMount() {
        this.populateClientData();
    }

    handleClientSelect(idx) {
        this.setState({ currentClientId: idx });
        this.populateUserData(idx);
    }

    handleOnEditClient(idx) {
        this.setState({ isEditMode: true, currentClientId: idx });
    }
    async handleOnDeleteClient(idx) {
        this.setState({ loading: true, currentClientId: null });
        await adminPanelService.deleteClient(idx);
        this.setState({ loading: false })
        await this.populateClientData();
    }

    async handleOnDeleteUser(idx) {
        console.log(idx);
        await adminPanelService.deleteUser(idx);
        this.populateUserData(this.state.currentClientId);
    }
    async handleOnSaveUser(user) {
        if (!user)
            return;
        /*let data = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            clientId: this.state.currentClientId,
            email: user.email,
            role: user.role,
            lockoutEnabled: user.lockoutEnabled
        }*/
        user.clientId = this.state.currentClientId;

        this.setState({ isEditMode: false })
        await adminPanelService.saveUser(user, user.id ? false : true);
        this.populateUserData(this.state.currentClientId);
    }

    handleOnClientSave(clientName, license) {
        this.setState({ isEditMode: false })

        let isNew = this.state.currentClientId === null;
        let data;
        if (isNew)
            data = { name: clientName, license: license };
        else
            data = { id: this.state.currentClientId, name: clientName, license: license };
        this.SaveClientData(data, isNew);
    }
    handleOnClientEditCancel() {
        this.setState({ isEditMode: false })
    }
    renderInner() {
        if (this.state.loading)
            return (<div><Spinner color="primary" /><p><em>Loading...</em></p></div>);

        let users = [];

        if (this.state.currentClientId !== null)
            users = this.state.clients.find(x => x.id == this.state.currentClientId).users;

        if (this.state.isEditMode) {
            let name = "";
            let license = "";
            if (this.state.currentClientId !== null) {
                var client = this.state.clients.find(x => x.id == this.state.currentClientId)
                name = client.name;
                license = client.license;
            }
            return (
                <ClientEditor clientName={name} license={license} onClickSave={this.handleOnClientSave} onClickCancel={this.handleOnClientEditCancel} />
            );
        }
        let userlist = "";
        if (this.state.currentClientId !== null) {
            userlist = <UserList users={users} onClickDelete={this.handleOnDeleteUser} onClickSave={this.handleOnSaveUser} />;
        }
        return (
            <>
                <ClientHeader clients={this.state.clients} currentClientId={this.state.currentClientId}
                    onSelectItem={this.handleClientSelect} onClickEditItem={this.handleOnEditClient}
                    onClickDeleteItem={this.handleOnDeleteClient} />
                {userlist}
            </>
        );
    }

    render() {
        return (
            <div>
                {this.renderInner()}
            </div>
        );
    }

    async populateClientData() {
        const data = await adminPanelService.getClientData();
        if (data !== null)
            this.setState({ clients: data })
        this.setState({ loading: false });
    }
    async populateUserData(clientId) {
        this.setState({ loading: true });
        const idx = this.state.clients.findIndex(x => x.id == clientId);
        if (idx == -1)
            return;

        const data = await adminPanelService.getUserData(clientId);
        if (data !== null) {
            let clients = this.state.clients;
            clients[idx] = data;
            this.setState({ clients: clients });
        }
        this.setState({ loading: false });
    }
    async SaveClientData(clientData, isNew) {
        this.setState({ loading: true });

        let cid = null;

        const ret = await adminPanelService.saveClient(clientData, isNew);
        if (ret !== null) {
            let c = this.state.clients;

            if (isNew) {
                let idx = c.findIndex(x => x.id == ret.id);
                if (idx === -1)
                    c.push(ret);
                else
                    c[idx] = ret;

                cid = ret.id;
            }
            else {
                let idx = c.findIndex(x => x.id == clientData.id);
                if (idx !== -1) {
                    c[idx].name = clientData.name;
                    cid = clientData.id;
                }
            }

            this.setState({ clients: c });
        }
        this.setState({ loading: false });
        this.handleClientSelect(cid);
    }
}
