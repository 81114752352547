import React, { useState } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, useField, FieldArray } from 'formik';
import { FormGroup, Input, Label, InputGroup, InputGroupButtonDropdown, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import * as Yup from 'yup';
import FormTextInput from '../FormTextInput';

function ResourceEditor(props) {
    let name = "";
    let category = "";
    let existingFilters = [];

    if (props.resource) {
        name = props.resource.name;
        category = props.resource.category;
        existingFilters = props.resource.filters;
    }

    let filters = props.filterTypes.map(x => {
        let currFilter = existingFilters.find(f => f.resourceGroupFilterId == x.id);
        if (currFilter == null) {
            currFilter = { resourceGroupFilterId: x.id, filter: "" };
        }
        return { filterType: x, filterValue: currFilter };
    })

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    return (
        <Formik
            initialValues={{ name: name, category: category, filters: filters }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, 'Name muss mindestens 3 Zeichen lang sein!')
                    .max(300, 'Name muss 300 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                /*filters: Yup.array()
                    .of(Yup.object().shape({
                        name: Yup.string()
                            .min(3, 'Name muss mindestens 3 Zeichen lang sein!')
                            .max(300, 'Name muss 300 Zeichen oder kürzer sein!')
                            .required('Benötigt')
                    }))*/
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                let id = null;
                if (props.resource && !props.add)
                    id = props.resource.id;

                props.onClickSave({ id: id, name: values.name, filters: values.filters.map(x => x.filterValue) });
            }}
            render={({ values, setFieldValue }) => {
                return <Form className="mt-2">
                    <h5>{props.resource && !props.add ? "Betriebsmittel bearbeiten" : "Neues Betriebsmittel"}</h5>
                    <Row form>
                        <Col md={6}>
                                <FormTextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                />
                        </Col>
                    </Row>
                    <hr />
                    {(values.filters && values.filters.length > 0) ? (
                        <>
                            <h6>Filter</h6>
                            {values.filters.map((filter, index) => (
                                <Row className="mt-2" form key={index}>
                                    <Col md={6}>
                                        <p>{filter.filterType.name}</p>
                                    </Col>
                                    <Col md={6}>
                                        <FormTextInput hideLabel name={`filters.${index}.filterValue.filter`} placeholder="Filter" />
                                    </Col>
                                </Row>
                            ))}
                            <hr />
                        </>
                    ) : null}

                    <div className="btn-toolbar">
                        <div className="btn-group mr-2">
                            <button type="submit" className="btn btn-primary">Speichern</button>
                        </div>
                        <div className="btn-group mr-2">
                            <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                        </div>
                    </div>
                </Form>
            }} />
    );
}
export default ResourceEditor;