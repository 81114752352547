import React from "react";
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardText, Button, Row, Col } from "reactstrap";
import ShowAuthorized from "./api-authorization/ShowAuthorized";

function ProjectCard(props) {

    return (
        <Card body>
            <CardTitle>
                <h5>{props.project.name}
                    <ShowAuthorized role={["Manager", "Admin"]}>
                        {props.project.isPublic ? " (Öffentlich)" : " (Privat)"}
                    </ShowAuthorized>
                </h5>
            </CardTitle>
            <CardText>{props.project.description}</CardText>
            <Row>
                {props.copyMode ?
                    <Col ld="6">
                        <Button onClick={props.onClickCopy} outline color="success">Kopieren</Button>
                    </Col>
                    :
                    <>
                        <Col ld="6">
                            <Button tag={Link} to={`projects/${props.project.id}`} outline color="primary">Bearbeiten</Button>
                        </Col>
                        <Col ld="6">
                            <Button outline color="danger" onClick={props.onClickDelete}>Löschen</Button>
                        </Col>
                    </>
                }
            </Row>
        </Card>
    );
}
export default ProjectCard;