import { GetApiRequest } from './ApiRequestService';

export class CommonDataService {

    async getEntries(resource) {
        const response = await GetApiRequest(`api/${resource}`, "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async getEntry(resource, id) {
        const response = await GetApiRequest(`api/${resource}/${id}`, "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async saveEntry(resource, id, data, isNew) {
        const response = await GetApiRequest(isNew ? `api/${resource}` : `api/${resource}/${id}`, isNew ? "POST" : "PUT", true, data);
        if (response.ok) {
            if (isNew)
                return await response.json();
            else
                return true;
        }
        return null;
    }
    async deleteEntry(resource, id) {
        const response = await GetApiRequest(`api/${resource}/${id}`, "DELETE", true);
        if (response.ok) {
            return true;
        }
        return false;
    }
}
const commonDataService = new CommonDataService();

export default commonDataService;
