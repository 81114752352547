import React, { useState, useEffect } from "react";
import { Button, Row, Col, ButtonToolbar, ButtonGroup, Nav, NavItem, NavLink } from 'reactstrap';
import { useRouteMatch, useParams, useHistory, Switch, Route, Redirect } from 'react-router-dom';
import editorDataService from '../api-data/EditorDataService'

import ResourceGroupHeader from './ResourceGroupHeader';
import ResourceGroupTabs from './ResourceGroupTabs';
import DeleteModal from '../DeleteModal';
import ResourceGroupEditor from './ResourceGroupEditor';
import LoadingComponent from "../LoadingComponent";
import ImportPage from "./ImportPage";

function ResourceGroupPage(props) {
    let { path, url } = useRouteMatch();
    let { id } = useParams();
    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);

    const matchGroup = useRouteMatch(
        [
            `${path}/resource/:groupId`,
            `${path}/check/:groupId`,
            `${path}/room/:groupId`,
            `${path}/edit/:groupId`
        ]
    );
    const groupId = matchGroup ? matchGroup.params["groupId"] : null;
    const group = groupId !== null ? props.groups.find(x => x.id == groupId) : null;

    //Helper to set the current path to a resourcegroup
    function redirectGroupId(id, tabPath = "resource", replace = false, newUrl = null) {
        let redPath = "";
        if (id === null) {
            redPath = `${newUrl ? newUrl : url}`;
        }
        else {
            redPath = `${newUrl ? newUrl : url}/${tabPath}/${id}`;
        }

        if (replace) {
            history.replace(redPath)
        }
        else {
            history.push(redPath);
        }
    }

    function setGroupData(group, isNew) {
        props.setGroups(prevGroups => {

            group.isSet = true;

            if (isNew) {
                return [...prevGroups, group];
            }
            else {
                return prevGroups.map(x => {
                    if (x.id === group.id)
                        return group;
                    else
                        return x;
                })
            }
        });
    }

    async function saveGroup(group, isNew) {
        group.projectId = id;
        let res = await editorDataService.saveResourceGroup(group, isNew);

        //Reload group if it isn't new
        if (!isNew) {
            res = await editorDataService.getResourceGroup(group.id);
            }
        if (res) {
            setGroupData(res, isNew);
            redirectGroupId(res.id, "resource");
            return;
        }
        else {
            redirectGroupId(null);
        }
    }

    async function deleteGroup(groupId) {
        let res = await editorDataService.deleteResourceGroup(groupId);
        if (res) {
            props.setGroups(prevGroups => prevGroups.filter(x=>x.id != groupId))
        }
    }

    async function setData() {
        setLoading(true);
        var res = await editorDataService.getResourceGroup(groupId)
        if (res !== null) {
            setGroupData(res);
        } else {
            redirectGroupId(null, null, true);
        }

        setLoading(false);
    }

    async function importGroup(groupId) {
        setLoading(true);
        var res = await editorDataService.importResourceGroup(props.projectId, groupId);
        if (res) {
            var oldGroup = props.groups.find(x => x.csId == res.csId);
            if (oldGroup)
                props.setGroups(prevGroups => prevGroups.filter(x => x.id != oldGroup.id))
            setGroupData(res, true)
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!loading) {
            if (groupId !== null && group && !group.isSet) {
                setData();
            }
            else if (groupId !== null && !group) {
                redirectGroupId(null, null, true)
            }
        }
    }, [groupId]);

    if (loading)
        return <LoadingComponent />

    return (
        <Switch>
            <Route path={`${path}/import`}>
                {props.isReadonly ?
                    <Redirect to={url} push={false} />
                    :
                    <ImportPage
                        importGroup={importGroup}
                        roomData={props.roomData}
                        redirectGroupId={redirectGroupId}
                        groups={props.groups}
                    />
                }
            </Route>
            <Route path={[`${path}/add`, `${path}/edit/:groupId`]}>
                {props.isReadonly ?
                    <Redirect to={url} push={false} />
                    :
                    <ResourceGroupEditor
                        onClickSave={saveGroup}
                        onClickCancel={() => redirectGroupId(group ? group.id : null)}
                        group={group} />
                }
            </Route>
            <Route>
                {props.isReadonly ?
                    null
                    :
                    <DeleteModal header="Gruppe löschen" label={"Wollen Sie die Gruppe " + (groupToDelete ? groupToDelete.name : "") + " wirklich löschen?"}
                        onClick={() => {
                            deleteGroup(groupToDelete.id);
                            redirectGroupId(null, null, true);
                            setGroupToDelete(null);
                        }}
                        onCancel={() => setGroupToDelete(null)}
                        show={!!groupToDelete} />
                }
                <ResourceGroupHeader
                    onClickNew={() => history.push(`${url}/add`)}
                    resourceGroups={props.groups}
                    currentGroup={group}
                    onSelect={(id) => redirectGroupId(id, "resource")}
                    onEdit={() => history.push(`${url}/edit/${group.id}`)}
                    onDelete={() => setGroupToDelete(group)}
                    onClickImport={() => history.push(`${url}/import`)}
                    isReadonly={props.isReadonly}
                    />

                {group ? <ResourceGroupTabs
                    roomData={props.roomData}
                    onSelectTab={(tab) => redirectGroupId(groupId, tab)}
                    group={group}
                    saveData={(x) => setGroupData(x, false)}
                    isReadonly={props.isReadonly}
                /> : <p>Keine Betriebsmittelgruppe ausgewählt!</p>}
            </Route>
        </Switch>
    );
}
export default ResourceGroupPage;