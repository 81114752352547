import React from 'react';
import { useField } from 'formik';

export default function FormCheckInput({ label, ...props }) {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <>
            <input className="form-check-input" type="checkbox" {...field} {...props} />
            <label className="form-check-label" htmlFor={props.id || props.name}>{label}</label>
        </>
    );
};