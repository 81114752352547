import React, { useState, useEffect } from "react";
import { ButtonToolbar, ButtonGroup, Button, Col, Row, Card, CardBody, CardHeader } from 'reactstrap';

function ImportProjectEntry(props) {

    return (
        <Card>
            <CardHeader>{props.project.name}</CardHeader>
            <CardBody>
                {props.project.resourceGroups.map(x =>
                    <Row key={x.id}>
                        <Col>{x.name + (props.groups.some(y => y.csId === x.csId) ? " (Importiert)" : "")}</Col>
                        <Col>
                            <ButtonToolbar className="float-right">
                                <ButtonGroup>
                                    <Button outline onClick={() => props.onSelect(x.id)} color="primary">Details</Button>
                                    <Button outline onClick={() => props.onClickImport(x.id)} color="success">{props.groups.some(y => y.csId === x.csId) ? "Aktualisieren" : "Importieren"}</Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                )}
            </CardBody>
        </Card>
    );
}
export default ImportProjectEntry;