import React, { useState } from "react";
import { Table, Button, ButtonToolbar, ButtonGroup } from "reactstrap";
import DeleteModal from '../DeleteModal';

function RoomList(props) {
    const [roomToDelete, setRoomToDelete] = useState(null);
    return (
        <div>
            <DeleteModal header="Raum löschen" label={"Wollen Sie den Raum " + (roomToDelete ? roomToDelete.name : "") + " wirklich löschen?"}
                onClick={() => {
                    props.onClickDelete(roomToDelete.id);
                    setRoomToDelete(null);
                }}
                onCancel={() => setRoomToDelete(null)}
                show={!!roomToDelete} />
            <Table>
                <thead>
                    <tr>
                        <th>ComSchäcke Id</th>
                        <th>Bezeichnung</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.rooms.map(room =>
                        <tr key={room.id}>
                            <th>{room.csId}</th>
                            <td>{room.name}</td>
                            <td>
                                <ButtonToolbar className="float-right">
                                    <ButtonGroup>
                                        <Button outline color="primary" onClick={() => props.onClickEdit(room.id)}>Bearbeiten</Button>
                                        <Button outline color="danger" onClick={() => setRoomToDelete(room)}>Löschen</Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </td>
                        </tr>)}
                </tbody>
            </Table>
        </div>
    );
}
export default RoomList;