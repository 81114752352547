import { GetApiRequest } from './ApiRequestService';

export class AdminPanelService {

    async getClientData() {
        const response = await GetApiRequest("api/client", "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async getUserData(clientId) {
        const response = await GetApiRequest(`api/client/${clientId}`, "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async saveUser(user, isNew) {
        const response = await GetApiRequest(isNew ? "api/user" : `api/user/${user.id}`, isNew ? "POST" : "PUT", true, user);
        if (response.ok) {
            if (isNew)
                return await response.json();
            else
                return true;
        }
        return null;
    }
    async deleteUser(userId) {
        const response = await GetApiRequest(`api/user/${userId}`, "DELETE", true);
        if (response.ok) {
            return true;
        }
        return false;
    }

    async saveClient(client, isNew) {
        const response = await GetApiRequest(isNew ? "api/client" : `api/client/${client.id}`, isNew ? "POST" : "PUT", true, client);
        if (response.ok) {
            if (isNew)
                return await response.json();
            else
                return true;
        }
        return null;
    }

    async deleteClient(clientId) {
        const response = await GetApiRequest(`api/client/${clientId}`, "DELETE", true);
        if (response.ok) {
            return true;
        }
        return false;
    }
}
const adminPanelService = new AdminPanelService();

export default adminPanelService;
