import React, { useState, useEffect } from "react";
import authService from './AuthorizeService';
import { NavItem } from 'reactstrap';

function ShowAuthorized(props) {

    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);


    async function populateAuthenticationState() {
        let isAuth = false;
        if (props.role) {

            if(typeof props.role === 'string')
                isAuth = await authService.isRole(props.role);
            else if (Array.isArray(props.role))
                isAuth = (await Promise.all(props.role.map(x => authService.isRole(x)))).some(x => x)
        }
        else {
            isAuth = await authService.isAuthenticated();
        }
        setReady(true);
        setAuthenticated(isAuth);
    }

    async function authenticationChanged() {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    }

    useEffect(() => {
        let _subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();
        return function cleanup() {
            authService.unsubscribe(_subscription);
        }
    }, []);

    if (!ready || !authenticated) {
        return <></>;
    } else {
        return props.children
    }
}
export default ShowAuthorized;