import React, { useState, useEffect } from "react";
import ProjectList from "./ProjectList"
import ProjectEditor from "./ProjectEditor";
import projectDataService from './api-data/ProjectDataService'
import { GetApiRequest } from './api-data/ApiRequestService';
import { Button, Row, Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import DeleteModal from './DeleteModal';
import LoadingComponent from './LoadingComponent';

function ProjectPage(props) {
    const [projects, setProjects] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [updateData, setUpdateData] = useState(true);
    const [projectToDelete, setProjectToDelete] = useState(null);

    const [isCopyMode, setCopyMode] = useState(false);
    const [addProjectDropdownOpen, setAddProjectDropdownOpen] = useState(false);
    const toggleAddProject = () => setAddProjectDropdownOpen(prevState => !prevState);

    useEffect(() => {
        if (updateData) {
            setLoading(true);
            projectDataService.getProjects().then(x => x !== null ? setProjects(x) : setProjects([]));
            setLoading(false);
            setUpdateData(false);
        }
    }, [updateData]);

    async function addProject(project) {
        if (!project)
            return;
        let data = {
            id: currentProject,
            name: project.name,
            description: project.description,
        }
        setEditMode(false);
        setCurrentProject(null);   
        await projectDataService.saveProject(data, currentProject ? false : true);
        setUpdateData(true);
    }
    async function deleteProject(id) {
        await projectDataService.deleteProject(id);
        setUpdateData(true);
    }

    async function copyProject(id) {
        if (isLoading)
            return;
        setLoading(true);
        setCopyMode(false);
        var response = await GetApiRequest(`api/project/copy/${id}`, "POST");
        if (response.ok) {
            setLoading(false);
            setUpdateData(true);
        }
        else {
            //Error
            setLoading(false);
        }
    }

    let proj = null;

    if (currentProject) {
        proj = projects.find(x => x.id == currentProject)
    }

    if (isLoading)
        return <LoadingComponent/>

    return (
        <div>
            {isEditMode ?
                <ProjectEditor onClickSave={addProject} onClickCancel={() => {
                    setEditMode(false);
                    setCurrentProject(null);
                }} project={proj} />
                :
                <>
                    <DeleteModal header="Projekt löschen" label={"Wollen Sie das Projekt " + (projectToDelete ? projectToDelete.name : "") + " wirklich löschen?"}
                        onClick={() => {
                            deleteProject(projectToDelete.id);
                            setProjectToDelete(null);
                        }}
                        onCancel={() => setProjectToDelete(null)}
                        show={!!projectToDelete} />
                    <Row>
                        <Col>
                            <h2>Projekte</h2>
                        </Col>
                        <Col>
                            {isCopyMode ?
                                <Button color="danger" outline onClick={() => setCopyMode(false)} className="float-right">Abbrechen</Button>
                                :
                                <Dropdown isOpen={addProjectDropdownOpen} toggle={toggleAddProject} className="float-right">
                                    <DropdownToggle caret outline color="success">
                                        Projekt hinzufügen
                            </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => setEditMode(true)}>Neues Projekt erstellen</DropdownItem>
                                        <DropdownItem onClick={() => { setCopyMode(true) }}>Projekt kopieren</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                }
                        </Col>
                    </Row>
                    <ProjectList
                        projects={projects}
                        copyMode={isCopyMode}
                        onClickDelete={(project) => setProjectToDelete(project)}
                        onClickEdit={(id) => {
                            setCurrentProject(id);
                            setEditMode(true);
                        }}
                        onClickCopy={copyProject}
                    />
                </>
            }
        </div>
    );
}
export default ProjectPage;