import React, { useState } from "react";
import { Table, Input, Button, Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import FormTextInput from '../FormTextInput';
import { GetApiRequest } from '../api-data/ApiRequestService'

function EmailSendPage(props) {

    const [email, setEmail] = useState(false);
    const [loading, setLoading] = useState(false);

    let subject = ""
    let content = "";

    async function sendEmail() {
        if (loading)
            return;
        setLoading(true);
        const response = await GetApiRequest(`api/email`, "POST", true, email);
        if (response.ok) {
            
        }
        setEmail(null);
        setLoading(false);
    }

    return (
        <>
            <Modal isOpen={!!email} toggle={() => setEmail(null)}>
                <ModalHeader toggle={() => setEmail(null)}>{"Email versenden?"}</ModalHeader>
                <ModalBody>
                    Wollen Sie diese Email wirklich an alle User versenden?
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={sendEmail}>{"Senden"}</Button>{' '}
                    <Button color="secondary" onClick={() => setEmail(null)}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
            <Formik
                initialValues={{ subject, content }}
                validationSchema={Yup.object({
                    subject: Yup.string()
                        .required('Benötigt'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    setEmail({ subject: values.subject, content: values.content });
                }}
            >
                <Form>
                    <h3>Email senden</h3>
                    <div className="form-group">
                        <FormTextInput
                            label="Überschrift"
                            name="subject"
                            type="text"
                            placeholder="Überschrift"
                        />
                    </div>
                    <div className="form-group">
                        <Label for="content">Html Text</Label>
                        <Field as="textarea" className="form-control" rows="10" type="textarea" name="content" />
                    </div>
                    <div className="btn-toolbar">
                        <div className="btn-group mr-2">
                            <button type="submit" className="btn btn-success">Senden</button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    );
}
export default EmailSendPage;