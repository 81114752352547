import React, { useState, useEffect } from "react";
import authService from './AuthorizeService';
import { NavItem, Card, CardBody, Spinner, Button } from 'reactstrap';
import styles from '../styles.module.css'
import { useHistory } from 'react-router-dom'
import { ApplicationPaths } from './ApiAuthorizationConstants';

function AuthorizationPathCard(props) {

    let history = useHistory();

    return <div className={styles.centerDiv}>
        <Card>
            <CardBody>
                {props.header ? <><h3>{props.header}</h3> <hr /></> : null}
                {props.message}
                {props.spinner ? <Spinner color="primary" /> : null}
                {props.loginButton ?
                    <>
                        <hr />
                        <Button color="primary" onClick={() => history.replace(ApplicationPaths.Login)}>
                            Login
                    </Button>
                    </> : null}
            </CardBody>
        </Card>
    </div>
}
export default AuthorizationPathCard;