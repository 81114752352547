import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';

export default function DeleteModal({ header, label, ...props }) {

    return (
        <Modal isOpen={props.show} toggle={props.onCancel}>
            <ModalHeader toggle={props.onCancel}>{header}</ModalHeader>
            <ModalBody>
                {label}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={props.onClick}>Löschen</Button>{' '}
                <Button color="secondary" onClick={props.onCancel}>Abbrechen</Button>
            </ModalFooter>
        </Modal>
    );
}