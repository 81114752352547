import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Label, InputGroup } from 'reactstrap'
import { useField, Field } from 'formik'

export function TimeDurationInput({ label, valueName, scaleName, className, emptyValue, scales }) {

    const [valueField] = useField(valueName);
    const [scaleField] = useField(scaleName);

    return (
        <>
            <Label for={valueName}>{label}</Label>
            <InputGroup>
                <Field id="function" {...scaleField} as="select" className="form-control">
                    <option value={emptyValue}>Kein Eintrag</option>
                    {scales.map(x => <option key={x} value={SCALE_JSON[x]}>{SCALE_NAMES[x]}</option>)}
                </Field>
                <Input type="number" {...valueField} className={className} disabled={scaleField.value === emptyValue} />
            </InputGroup>
        </>
    )
}

export const SCALES = ['minute', 'hour', 'day', 'week', 'month', 'year'];

TimeDurationInput.propTypes = {
    value: PropTypes.number,
    scale: PropTypes.oneOf(SCALES),
    onChange: PropTypes.func,
    className: PropTypes.string
}

TimeDurationInput.defaultProps = {
    scale: 'minute',
    scales: SCALES
}

export const SCALE_JSON = {
    minute: 0,
    hour: 1,
    day: 2,
    week: 3,
    month: 4,
    year: 5
}

export const SCALE_NAMES = {
    minute: "Minute",
    hour: "Stunde",
    day: "Tag",
    week: "Woche",
    month: "Monat",
    year: "Jahr"
}

export default TimeDurationInput