import React, { useState, useEffect } from "react";
import projectDataService from '../api-data/ProjectDataService'
import commonDataService from '../api-data/CommonDataService'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams, Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';

import ProjectHeader from './ProjectHeader';
import ResourceGroupPage from './ResourceGroupPage';
import LoadingComponent from '../LoadingComponent';
import ProjectEditor from '../ProjectEditor';
import DeleteModal from '../DeleteModal';
import editorDataService from "../api-data/EditorDataService";

function MainEditor(props) {
    let { path, url } = useRouteMatch();
    let { id } = useParams();
    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);
    const [groups, setGroups] = useState(null);
    const [roomData, setRoomData] = useState(null);

    const [isDeleteMode, setDeleteMode] = useState(false);
    const [isPublishMode, setPublishMode] = useState(false);

    const isReadonly = project ? project.isPublic : true;

    useEffect(() => {
        if (!project && !loading) {
            setData();
        }
    });

    async function saveProject(proj) {
        let project = { id: id, name: proj.name, description: proj.description }
        let res = await projectDataService.saveProject(project, false);
        if (res) {
            setProject({ id: id, name: proj.name, description: proj.description, resourceGroups: groups, isPublic: false })
        }
        history.push(url);
    }

    async function deleteProject() {
        let res = await projectDataService.deleteProject(project.id);
        if (res) {
            history.push(path = "")
        }
    }

    async function publishProject() {
        setPublishMode(false);
        const setPublish = !isReadonly;
        const res = await editorDataService.publishProject(project.id, setPublish);
        if (res) {
            setProject({ id: project.id, name: project.name, description: project.description, resourceGroups: groups, isPublic: setPublish });
        }
    }

    async function setData() {
        setLoading(true);
        let rooms = await commonDataService.getEntries("room")

        setRoomData(rooms)

        let res = await projectDataService.getProject(id);
        if (res != null) {
            setProject(res);
            setGroups(res.resourceGroups);
        }
        else {
            history.push("");
        }
        setLoading(false);
    }

    if (loading)
        return (<LoadingComponent />);

    if (project) {
        return (
            <Switch>
                <Route exact path={`${path}/edit`}>
                    {isReadonly ?
                        <Redirect to={url} push={false}/>
                        :
                        <ProjectEditor
                            project={project}
                            onClickCancel={() => history.goBack()}
                            onClickSave={saveProject}
                        />
                    }
                </Route>
                <Route>
                    <div>
                        <DeleteModal header="Gruppe löschen" label={"Wollen Sie das Projekt " + (project.name) + " wirklich löschen?"}
                            onClick={() => {
                                setDeleteMode(false);
                                deleteProject();
                            }}
                            onCancel={() => setDeleteMode(false)}
                            show={isDeleteMode} />

                        <Modal isOpen={isPublishMode} toggle={() => setPublishMode(false)}>
                            <ModalHeader toggle={() => setPublishMode(false)}>{!isReadonly ? "Projekt veröffentlichen" : "Projekt privat stellen"}</ModalHeader>
                            <ModalBody>
                                {!isReadonly ?
                                    "Wollen Sie das Projekt " + (project.name) + " wirklich veröffentlichen? Es kann erst wieder bearbeitet werden wenn es wieder privat gesetzt wird!"
                                    :
                                    "Wollen Sie das Projekt " + (project.name) + " wieder auf Privat stellen? Das Projekt ist dann wieder bearbeitbar."
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="warning" onClick={publishProject}>{!isReadonly ? "Veröffentlichen" : "Privat stellen"}</Button>{' '}
                                <Button color="secondary" onClick={() => setPublishMode(false)}>Abbrechen</Button>
                            </ModalFooter>
                        </Modal>

                        <ProjectHeader
                            onClickEdit={() => history.push(`${url}/edit`)}
                            onClickDelete={() => setDeleteMode(true)}
                            onClickPublish={() => setPublishMode(true)}
                            project={project}
                            isReadonly={isReadonly}
                        />
                        <hr />
                        <ResourceGroupPage projectId={id} groups={groups} setGroups={setGroups} roomData={roomData} isReadonly={isReadonly} />
                    </div>
                </Route>
            </Switch>
        );
    }
    return (<div />);
}
export default MainEditor;