import React, { useState } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Row, Col, Button, ButtonToolbar, Table, ButtonGroup, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import DeleteModal from '../DeleteModal';
import ResourceEditor from './ResourceEditor';

function ResourcePage(props) {
    let { path, url } = useRouteMatch();
    let history = useHistory();

    const [resourceToDelete, setResourceToDelete] = useState(null);
    const [isAddSelectMode, setAddSelectMode] = useState(false);
    const [addResourceDropdownOpen, setAddResourceDropdownOpen] = useState(false);
    const toggleAddResource = () => setAddResourceDropdownOpen(prevState => !prevState);

    const resourceIdMatch = useRouteMatch(`${path}/edit/:resourceId`);
    const resourceId = resourceIdMatch ? resourceIdMatch.params["resourceId"] : null;
    const resource = resourceId !== null ? props.resources.find(x => x.id == resourceId) : null;

    const [editorResource, setEditorResource] = useState(null);

    function addResource(template) {
        setEditorResource(template);
        setAddSelectMode(false);
        history.push(`${url}/add`)
    }

    return (
        <Switch>
            <Route path={[`${path}/add`, `${path}/edit/:resourceId`]}>
                {props.isReadonly ?
                    <Redirect to={url} push={false} />
                    :
                    <ResourceEditor
                        resource={resource ? resource : editorResource}
                        onClickSave={props.saveResource}
                        onClickCancel={history.goBack}
                        filterTypes={props.filterTypes}
                        add={!resource}
                    />
                }
            </Route>
            <Route>
                <div className="mt-2">
                    {props.isReadonly ? null :
                        <DeleteModal
                            header="Betriebsmittel löschen"
                            label={"Wollen Sie das Betriebsmittel " + (resourceToDelete ? resourceToDelete.name : "") + " wirklich löschen?"}
                            onClick={() => {
                                props.deleteResource(resourceToDelete.id);
                                setResourceToDelete(null);
                            }}
                            onCancel={() => setResourceToDelete(null)}
                            show={!!resourceToDelete} />
                    }
                    <Row>
                        <Col>
                            <h5>{isAddSelectMode ? "Vorlage auswählen:" : "Betriebsmittel"}</h5>
                        </Col>
                        {props.isReadonly ? null :
                            <Col>
                                {!isAddSelectMode ?
                                    <Dropdown isOpen={addResourceDropdownOpen} toggle={toggleAddResource} className="float-right">
                                        <DropdownToggle caret outline color="success">
                                            Betriebsmittel hinzufügen
                                    </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => addResource(null)}>Neues Betriebsmittel erstellen</DropdownItem>
                                            <DropdownItem onClick={() => setAddSelectMode(true)}>Betriebsmittel kopieren</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    :
                                    <Button onClick={() => setAddSelectMode(false)} className="float-right" outline color="danger">Abbrechen</Button>
                                }
                            </Col>
                        }
                    </Row>
                    {props.resources.length > 0 ?
                        <Table className="mt-2">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {props.filterTypes.map((type) => <th key={type.id}>{type.name}</th>)}
                                    {props.isReadonly ? null :
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {props.resources.map(resource =>
                                    <tr key={resource.id}>
                                        <td>{resource.name}</td>
                                        {props.filterTypes.map((type) => <td key={type.id}>{resource.filters.find(f => f.resourceGroupFilterId == type.id)?.filter ?? ""}</td>)}
                                        {props.isReadonly ? null :
                                            <td>
                                                <ButtonToolbar className="float-right">
                                                    <ButtonGroup>
                                                        {isAddSelectMode ?
                                                            <Button outline color="success" onClick={() => addResource(resource)}>Kopieren</Button>
                                                            :
                                                            <>
                                                                <Button outline color="primary" onClick={() => history.push(`${url}/edit/${resource.id}`)}>Bearbeiten</Button>
                                                                <Button outline color="danger" onClick={() => setResourceToDelete(resource)}>Löschen</Button>
                                                            </>
                                                        }
                                                    </ButtonGroup>
                                                </ButtonToolbar>
                                            </td>
                                        }
                                    </tr>)}
                            </tbody>
                        </Table>
                        :
                        <p>Es sind noch keine Betriebsmittel erstellt worden!</p>}
                </div>
            </Route>
        </Switch>
    );
}
export default ResourcePage;