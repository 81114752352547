import authService from '../api-authorization/AuthorizeService'

export async function GetApiRequest(path, method, useAuth = true, body = null, asJsonObject = true) {
    let token = null;
    let headers = asJsonObject ? { 'Content-Type': 'application/json' } : {}
    if (useAuth) {
        token = await authService.getAccessToken();
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
    }

    const response = await fetch(path, {
        method: method,
        headers: headers,
        body: body!==null ? asJsonObject ? JSON.stringify(body) : body : null
    });
    return response;
}