import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faBriefcase,
    faPaperPlane,
    faQuestion,
    faClipboardCheck,
    faUsersCog,
    faImage,
    faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import AuthorizeNavItem from './api-authorization/AuthorizeNavItem';

const SideBar = ({ isOpen, toggle }) => {
    return (
        <div className={classNames("sidebar", { "is-open": isOpen })}>
            <div className="sidebar-header">
                <span color="info" onClick={toggle} style={{ color: "#fff" }}>
                    &times;
            </span>
                <h3>BML Editor</h3>
            </div>
            <div className="side-menu">
                <Nav vertical className="list-unstyled pb-3">
                    <p>Betriebsmittel Editor</p>

                    <NavItem>
                        <NavLink tag={Link} to={"/"}>
                            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                        Projekte
                        </NavLink>
                    </NavItem>
                    <AuthorizeNavItem role="Admin">
                        <NavLink tag={Link} to={"/room"}>
                            <FontAwesomeIcon icon={faHome} className="mr-2" />
                            Räume erstellen
                        </NavLink>
                    </AuthorizeNavItem>
                    <AuthorizeNavItem role="Admin">
                        <NavLink tag={Link} to={"/manage-clients"}>
                            <FontAwesomeIcon icon={faUsersCog} className="mr-2" />
                            Mandanten bearbeiten
                        </NavLink>
                    </AuthorizeNavItem>
                    <AuthorizeNavItem role="Admin">
                        <NavLink tag={Link} to={"/email"}>
                            <FontAwesomeIcon icon={faUsersCog} className="mr-2" />
                            Email versenden
                        </NavLink>
                    </AuthorizeNavItem>
                </Nav>
            </div>
        </div>
    );
};

export default SideBar;