import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

export default function ClientHeader(props) {

    const [showModal, setShowModal] = useState(false);

    function selectItem(id) {
        console.log('Item selected.');
        props.onSelectItem(id);
    }
    function clickNewItem(e) {
        console.log('Create new Item selected.')
        props.onClickEditItem(null);
    }
    function clickEdit() {
        console.log('Edit Item selected.')
        props.onClickEditItem(props.currentClientId);
    }

    let currentClient = props.clients.find(x => x.id == props.currentClientId);
    return (
        <div>
            <DeleteModal header="Mandant löschen" label={"Wollen Sie den Mandanten " + (currentClient ? currentClient.name : "") + " wirklich löschen?"}
                onClick={() => props.onClickDeleteItem(props.currentClientId)}
                onCancel={() => setShowModal(false)}
                show={showModal} />
            <div className="row justify-content-between">
                <h2>Mandant: </h2>
                <button onClick={clickNewItem} type="button" className="btn btn-success">Neuer Mandant</button>
            </div>
            {props.clients.length == 0 ? <p>Keine Mandanten vorhanden, erstelle einen neuen.</p> :
                <div className="btn-toolbar">
                    <div className="btn-group mr-2">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {currentClient != null ? currentClient.name : "Mandant auswählen"}
                            </button>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {props.clients.map(client =>
                                    <button key={client.id} className="dropdown-item" onClick={() => selectItem(client.id)}>{client.name}</button>
                                )}
                            </div>
                        </div>
                    </div>

                    {currentClient ?
                        <><div className="btn-group mr-2">
                            <button type="button" onClick={clickEdit} className="btn btn-primary">Bearbeiten</button>
                        </div>
                            <div className="btn-group mr-2">
                                <button type="button" onClick={() => setShowModal(true)} className="btn btn-danger">Löschen</button>
                            </div></>
                        : <div></div>}
                </div>
            }
        </div>
    )
}