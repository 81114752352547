import React from "react";
import { Button, Row, Col, ButtonToolbar, ButtonGroup } from 'reactstrap';
import ShowAuthorized from "../api-authorization/ShowAuthorized";

function ProjectHeader(props) {
    return (
        <div>
            <Row>
                <Col>
                    <h2>{props.project.name}</h2>
                </Col>
                <Col>
                    <ButtonToolbar className="float-right">
                        <ButtonGroup>
                            {props.isReadonly ? null : <Button outline onClick={props.onClickEdit} color="primary">Bearbeiten</Button>}
                            <ShowAuthorized role={["Admin", "Manager"]}>
                                <Button outline onClick={props.onClickPublish} color="warning">{props.isReadonly ? "Privat stellen" : "Veröffentlichen"}</Button>
                            </ShowAuthorized>
                            <Button outline onClick={props.onClickDelete} color="danger">Löschen</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{props.project.description}</p>
                </Col>
            </Row>
        </div>
    );
}
export default ProjectHeader;