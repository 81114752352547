import React, {useState} from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import FormTextInput from './FormTextInput';
import FormCheckInput from './FormCheckInput';
import { FormGroup, Label } from 'reactstrap';

export default function UserEditor(props) {
    const empty = "empty";


    let email = "";
    let role = empty;
    let firstName = "";
    let lastName = "";
    let lockoutEnabled = false;
    let internalUser = false;

    if (props.user) {
        email = props.user.email;
        role = props.user.role ? props.user.role : empty;
        firstName = props.user.firstName ?? "";
        lastName = props.user.lastName ?? "";
        lockoutEnabled = props.user.lockoutEnabled ?? false;
        internalUser = props.user.internalUser ?? false;
    }

    return (
        <Formik
            initialValues={{ firstName: firstName, lastName: lastName, email: email, role: role, lockoutEnabled, internalUser, passwordInput: "" }}
            validationSchema={Yup.object({
                firstName: Yup.string()
                    .min(3, 'Vorname muss mindestens 3 Zeichen lang sein!')
                    .max(30, 'Vorname muss 30 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                firstName: Yup.string()
                    .min(3, 'Nachname muss mindestens 3 Zeichen lang sein!')
                    .max(30, 'Nachname muss 30 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                email: Yup.string().email('Email ist nicht valid!').required('Benötigt'),
                internalUser: Yup.bool(),
                passwordInput: Yup.string().when('internalUser', {is: true, then: Yup.string().min(6, "Passwort muss mindestens 6 Zeichen lang sein"), otherwise: Yup.string()})
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                props.onClickSave({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    role: values.role === empty ? null : values.role,
                    lockoutEnabled: values.lockoutEnabled,
                    internalUser: values.internalUser,
                    passwordInput: values.passwordInput
                });            
            }}
        >
            {   ({ values }) => (
                <Form>
                    <h3>{props.user ? "Benutzer bearbeiten" : "Neuer Benutzer"}</h3>
                    <div className="form-group">
                        <FormTextInput
                            label="Vorname"
                            name="firstName"
                            type="text"
                            placeholder="Vorname"
                        />
                    </div>
                    <div className="form-group">
                        <FormTextInput
                            label="Nachname"
                            name="lastName"
                            type="text"
                            placeholder="Nachname"
                        />
                    </div>
                    <FormGroup check row>
                        <FormCheckInput
                            disabled={!!props.user}
                            label="Interner Benutzer"
                            name="internalUser"
                            type="checkbox"
                        />
                    </FormGroup>
                    <div className="form-group">
                        <FormTextInput
                            disabled={!values.internalUser}
                            label="Passwort"
                            name="passwordInput"
                            type="password"
                        />
                    </div>
                    <div className="form-group">
                        <FormTextInput
                            label="Email"
                            name="email"
                            type="text"
                            placeholder="Email"
                        />
                    </div>
                    <FormGroup>
                        <Label for="role">Rolle</Label>
                        <Field id="role" as="select" className="form-control" name="role">
                            <option value={empty}>Keine Rolle</option>
                            <option value="Manager">Manager</option>
                        </Field>
                    </FormGroup>
                    <FormGroup check row>
                        <FormCheckInput
                            label="Deaktiviert"
                            name="lockoutEnabled"
                            type="checkbox"
                        />
                    </FormGroup>

                    <div className="btn-toolbar">
                        <div className="btn-group mr-2">
                            <button type="submit" className="btn btn-primary">Speichern</button>
                        </div>
                        <div className="btn-group mr-2">
                            <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}