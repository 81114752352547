import React from 'react';
import { useField } from 'formik';

export default function FormTextInput({ label, hideLabel, ...props }) {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <>
            {!hideLabel ? (<label htmlFor={props.id || props.name}>{label}</label>) : null}
            <input className={meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} type="text" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </>
    );
};