import React, { useState, useEffect } from "react";
import { useRouteMatch, useParams, useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { Row, Col, Button } from "reactstrap";
import LoadingComponent from '../LoadingComponent';
import commonDataService from '../api-data/CommonDataService'
import RoomEditor from './RoomEditor';
import RoomList from './RoomList';

import { addEntryToCollection } from '../Helper/CommonDataFunctions'

function RoomEditorPage(props) {

    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);

    let { path, url } = useRouteMatch();
    const roomIdMatch = useRouteMatch(`${path}/edit/:roomId`);
    const roomId = roomIdMatch ? roomIdMatch.params["roomId"] : null;
    const room = roomId !== null ? rooms.find(x => x.id == roomId) : null;

    let history = useHistory();

    useEffect(() => {
        setLoading(true);
        commonDataService.getEntries("room").then(x => x !== null ? setRooms(x) : setRooms([]));
        setLoading(false);
    }, []);

    async function saveRoom(room) {
        
        const isNew = roomId === null;
        if (!isNew)
            room.id = roomId;
        let res = await commonDataService.saveEntry("room", room.id, room, isNew);

        if (res) {
            setRooms(addEntryToCollection(isNew ? res : room, rooms, isNew));
        }
        history.push(path);
    }
    async function deleteRoom(id) {
        let res = await commonDataService.deleteEntry("room", id);
        if (res) {
            setRooms(rooms.filter(x => x.id !== id));
        }
    }

    if (loading)
        return <LoadingComponent />;

    return (
        <Switch>
            <Route path={[`${path}/add`, `${path}/edit/:roomId`]}>
                <RoomEditor
                    onClickSave={saveRoom}
                    onClickCancel={() => history.push(path)}
                    room={room} />
            </Route>
            <Route>
                <div>
                    <Row>
                        <Col>
                            <h2>Räume</h2>
                        </Col>
                        <Col>
                            <Button className="float-right" onClick={() => history.push(`${path}/add`)} color="success">Neuer Raum</Button>
                        </Col>
                    </Row>
                    <hr />
                    {rooms.length > 0 ?
                        <RoomList rooms={rooms}
                            onClickEdit={(id) => history.push(`${path}/edit/${id}`)}
                            onClickDelete={deleteRoom}
                        />
                        :
                        <p>Keine Räume vorhanden</p>}
                </div>
            </Route>
        </Switch>
    );
}
export default RoomEditorPage;