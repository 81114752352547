import { GetApiRequest } from './ApiRequestService';

export class EditorDataService {

    async getResourceGroup(id) {
        const response = await GetApiRequest(`api/editor/group/${id}`, "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async getResourceGroupFilters(id) {
        const response = await GetApiRequest(`api/editor/filters/${id}`, "GET", true)
        if (response.ok) {
            return await response.json();
        }
        return null;
    }

    async saveResourceGroup(group, isNew) {
        const response = await GetApiRequest(isNew ? `api/editor/group` : `api/editor/group/${group.id}`, isNew ? "POST" : "PUT", true, group);
        if (response.ok) {
            if (isNew)
                return await response.json();
            else
                return true;
        }
        return null;
    }

    async setGroupInRoom(groupId, groupInRoom, value) {
        const response = await GetApiRequest(`api/groupInRoom/${groupId}`, value ? "POST" : "DELETE", true, groupInRoom)
        if (response.ok) {
            return true;
        }
        return false;
    }

    async deleteResourceGroup(id) {
        const response = await GetApiRequest(`api/editor/group/${id}`, "DELETE", true);
        if (response.ok) {
            return true;
        }
        return false;
    }

    async publishProject(id, setPublish) {
        const response = await GetApiRequest(`api/editor/publish/${id}`, "POST", true, setPublish);
        if (response.ok) {
            return true;
        }
        return false;
    }

    async getPublishedProjects() {
        const response = await GetApiRequest(`api/import`, "GET", true);
        if (response.ok) {
            return response.json();
        }
        return null;
    }

    async importResourceGroup(projectId, groupId) {
        const response = await GetApiRequest(`api/import/${groupId}`, "POST", true, projectId);
        if (response.ok) {
            return response.json();
        }
        return null;
    }
}
const editorDataService = new EditorDataService();

export default editorDataService;
