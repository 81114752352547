export function addEntryToCollection(entry, collection, isNew) {

    if (!isNew) {
        let idx = collection.findIndex(x => x.id == entry.id);

        collection[idx] = entry;
    }
    else {
        collection.push(entry);
    }
    return collection;
}