import React, { useState } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { NavMenu } from './NavMenu';
import SideBar from './SideBar';
import TopBar from './TopBar';
import classNames from 'classnames';

const Layout = (props) => {
    const [sidebarIsOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
    return (
        <div className="App wrapper" style={{ alignItems: "stretch" }}>
            <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
            <Container fluid className={classNames("content", { "is-open": sidebarIsOpen })} >
                <TopBar toggleSidebar={toggleSidebar} />
                <Card className="card shadow-sm p-3 mb-5 bg-white rounded bg-light">
                    <CardBody>
                        {props.children}
                    </CardBody>
                </Card>
            </Container>
        </div >
    );
};
export default Layout;
