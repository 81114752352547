import React from 'react';
import { Formik, Field, Form, ErrorMessage, useField, FieldArray } from 'formik';
import * as Yup from 'yup';
import FormTextInput from '../FormTextInput';
import { Row, Col, Button } from 'reactstrap';

export default function ResourceGroupEditor(props) {

    const MAX_FILTERS = 5;

    let name = "";
    let description = "";
    let filters = [];

    if (props.group) {
        name = props.group.name;
        filters = props.group.filters;
        description = props.group.description;
    }

    return (
        <Formik
            initialValues={{
                name: name,
                filters: filters,
                description: description
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, 'Gruppenname muss mindestens 3 Zeichen lang sein!')
                    .max(80, 'Gruppenname muss 80 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                description: Yup.string()
                    .max(80, 'Gruppenbezeichnung dar höchstens 80 Zeichen sein!'),
                filters: Yup.array()
                    .of(Yup.object().shape({
                        name: Yup.string()
                            .min(3, 'Name muss mindestens 3 Zeichen lang sein!')
                            .max(300, 'Name muss 300 Zeichen oder kürzer sein!')
                            .required('Benötigt')
                    }))
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const isNew = !props.group;

                if (isNew) {
                    props.onClickSave({ id: null, name: values.name, filters: values.filters, description: values.desciption }, true)
                }
                else {
                    let group = props.group;
                    group.name = values.name;
                    group.filters = values.filters;
                    group.description = values.description;
                    props.onClickSave(group);
                }
            }}
        >
            {
                ({ values }) => (
                    <Form>
                        <h3>{props.group ? "Gruppe bearbeiten" : "Neue Gruppe"}</h3>
                        <div className="form-group">
                            <FormTextInput
                                label="Gruppenname"
                                name="name"
                                type="text"
                                placeholder="Gruppenname"
                            />
                        </div>
                        <div className="form-group">
                            <FormTextInput
                                label="Beschreibung"
                                name="description"
                                type="text"
                                placeholder="Beschreibung"
                            />
                        </div>
                        <FieldArray
                            name="filters"
                            render={arrayHelpers => (
                                <div>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <h6>Filter</h6>
                                        </Col>
                                        <Col>
                                            <Button disabled={values.filters.length >= MAX_FILTERS} outline color="success" className="float-right" onClick={() => { arrayHelpers.push({ name: "" });}}>
                                                Filter hinzufügen
                                        </Button>
                                        </Col>
                                    </Row>
                                    {values.filters && values.filters.length > 0 ? (
                                        <div>
                                            <Row>
                                                <Col>
                                                    <b>Bezeichnung</b>
                                                </Col>
                                            </Row>

                                            {values.filters.map((filter, index) => (
                                                <Row className="mt-2" form key={index}>
                                                    <Col md={10}>
                                                        <FormTextInput hideLabel name={`filters.${index}.name`} placeholder="Bezeichnung" />
                                                    </Col>

                                                    <Col md={2}>
                                                        <Button className="float-right" outline color="danger" onClick={() => arrayHelpers.remove(index)}>
                                                            Löschen
                                                    </Button>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    )
                                        :
                                        <p> Es sind noch keine Filter vorhanden </p>
                                    }
                                </div>
                            )}
                        />
                        <hr/>
                        <div className="btn-toolbar">
                            <div className="btn-group mr-2">
                                <button type="submit" className="btn btn-primary">Speichern</button>
                            </div>
                            <div className="btn-group mr-2">
                                <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik >
    );
}