import { GetApiRequest } from './ApiRequestService';

export class ProjectDataService {

    async getProjects() {
        const response = await GetApiRequest("api/project", "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
    async getProject(id) {
        const response = await GetApiRequest(`api/project/${id}`, "GET", true);
        if (response.ok) {
            return await response.json();
        }
        return null;
    }
    async saveProject(project, isNew) {
        const response = await GetApiRequest(isNew ? "api/project" : `api/project/${project.id}`, isNew ? "POST" : "PUT", true, project);
        if (response.ok) {
            if (isNew)
                return await response.json();
            else
                return true;
        }
        return null;
    }
    async deleteProject(projectId) {
        const response = await GetApiRequest(`api/project/${projectId}`, "DELETE", true);
        if (response.ok) {
            return true;
        }
        return false;
    }
}
const projectDataService = new ProjectDataService();

export default projectDataService;
