import React, { useState } from 'react';
import { Button, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Col, ButtonToolbar, ButtonGroup } from 'reactstrap';

function ResourceGroupHeader(props) {

    const [addGroupDropdownOpen, setAddGroupDropdownOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const toggleAddGroup = () => setAddGroupDropdownOpen(prevState => !prevState);

    let resourceGroups = props.resourceGroups ? props.resourceGroups : [];

    return (
        <div>
            <Row>
                <Col>
                    <h4>Betriebsmittelgruppen:</h4>
                </Col>
                {props.isReadonly ? null :
                    <Col>
                        <Dropdown isOpen={addGroupDropdownOpen} toggle={toggleAddGroup} className="float-right">
                            <DropdownToggle caret outline color="success">
                                Betriebsmittelgruppe hinzufügen
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={props.onClickNew}>Neue Betriebsmittelgruppe erstellen</DropdownItem>
                                <DropdownItem onClick={props.onClickImport}>Standard-Betriebsmittelgruppen importieren</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                }
            </Row>
            <Row className="mt-2">
                <Col>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>
                            {props.currentGroup ? (props.currentGroup.name + (!!props.currentGroup.description ? " (" + props.currentGroup.description + ")" : "")) : "Betriebsmittelgruppe auswählen"}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>Betriebsmittelgruppen:</DropdownItem>

                            {resourceGroups.map(x => <DropdownItem onClick={() => props.onSelect(x.id)} key={x.id}>{(x.name + (!!x.description ? " (" + x.description + ")" : ""))}</DropdownItem>)}
                            {resourceGroups.length === 0 ? <DropdownItem disabled>Keine Betriebsmittelgruppen vorhanden</DropdownItem> : <></>}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                {props.currentGroup && !props.isReadonly ?
                    <Col>
                        <ButtonToolbar className="float-right">
                            <ButtonGroup>
                                <Button outline onClick={props.onEdit} color="primary">Bearbeiten</Button>
                                <Button outline onClick={props.onDelete} color="danger">Löschen</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                    : null}
            </Row>
        </div>
    );
}
export default ResourceGroupHeader;