import React, { useState } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Row, Col, Button, ButtonToolbar, Table, ButtonGroup } from 'reactstrap';
import { GetApiRequest } from '../api-data/ApiRequestService';
import { saveAs } from 'file-saver';
import DeleteModal from '../DeleteModal';
import CheckEditor from './CheckEditor';
import { SCALE_NAMES } from '../TimeDurationInput';
import { SCALES } from '../TimeDurationInput';

function CheckPage(props) {
    let { path, url } = useRouteMatch();
    let history = useHistory();

    const [checkToDelete, setCheckToDelete] = useState(null);

    const checkIdMatch = useRouteMatch(`${path}/edit/:checkId`);
    const checkId = checkIdMatch ? checkIdMatch.params["checkId"] : null;
    const check = checkId !== null ? props.checks.find(x => x.id == checkId) : null;

    async function downloadFile() {
        
        GetApiRequest(`api/check/${checkId}/file`, "GET", true)
            .then(function (response) {
                return response.blob();
            }).then(function (blob) {
                saveAs(blob, check.fileName);
            }).catch(function () {
                //Todo: Error Handling
            })
    }

    return (
        <Switch>
            <Route path={[`${path}/add`, `${path}/edit/:checkId`]}>
                {props.isReadonly ?
                    <Redirect to={url} push={false} />
                    :
                    <CheckEditor
                        check={check}
                        onClickSave={props.saveCheck}
                        onClickCancel={history.goBack}
                        onDownloadFile={() => downloadFile()}
                    />
                }
            </Route>
            <Route>
                <div className="mt-2">
                    {props.isReadonly ? null :
                        <DeleteModal header="Check löschen" label={"Wollen Sie den Check " + (checkToDelete ? checkToDelete.name : "") + " wirklich löschen?"}
                            onClick={() => {
                                props.deleteCheck(checkToDelete.id);
                                setCheckToDelete(null);
                            }}
                            onCancel={() => setCheckToDelete(null)}
                            show={!!checkToDelete} />
                    }
                    <Row>
                        <Col>
                            <h5>Checks</h5>
                        </Col>
                        {props.isReadonly ? null :
                            <Col>
                                <Button onClick={() => history.push(`${url}/add`)} className="float-right" outline color="success">Neuer Check</Button>
                            </Col>
                        }
                    </Row>
                    {props.checks.length > 0 ?
                        <Table className="mt-2">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Intervall</th>
                                    {props.isReadonly ? null : <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.checks.map(check =>
                                    <tr key={check.id}>
                                        <td>{check.name}</td>
                                        <td>{check.interval + " " + SCALE_NAMES[SCALES[check.intervalUnit]]}</td>
                                        {props.isReadonly ? null :
                                            <td>
                                                <ButtonToolbar className="float-right">
                                                    <ButtonGroup>
                                                        <Button outline color="primary" onClick={() => history.push(`${url}/edit/${check.id}`)}>Bearbeiten</Button>
                                                        <Button outline color="danger" onClick={() => setCheckToDelete(check)}>Löschen</Button>
                                                    </ButtonGroup>
                                                </ButtonToolbar>
                                            </td>
                                        }
                                    </tr>)}
                            </tbody>
                        </Table>
                        :
                        <p>Es sind noch keine Checks erstellt worden!</p>}
                </div>
            </Route>
        </Switch>
    );
}
export default CheckPage;