import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import editorDataService from '../api-data/EditorDataService'
import LoadingComponent from '../LoadingComponent'
import ImportProjectEntry from './ImportProjectEntry'
import ResourceGroupTabs from './ResourceGroupTabs'

function ImportPage(props) {

    let { path, url } = useRouteMatch();
    let history = useHistory();

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [importGroupId, setImportGroupId] = useState(null);

    useEffect(() => {
        getProjects();
    }, []);

    async function getProjects() {
        setLoading(true);
        var res = await editorDataService.getPublishedProjects();
        if (res)
            setProjects(res);
        setLoading(false);
    }

    async function importGroup() {
        props.importGroup(importGroupId);
    }

    const matchGroup = useRouteMatch(
        [
            `${path}/resource/:groupId`,
            `${path}/check/:groupId`,
            `${path}/room/:groupId`
        ]
    );

    if (loading)
        return <LoadingComponent />

    const groupId = matchGroup ? matchGroup.params["groupId"] : null;
    const groups = [].concat.apply([], projects.map(x => x.resourceGroups))

    const group = groupId !== null ? groups.find(x => x.id === groupId) : null;

    const currImportGroup = importGroupId !== null ? groups.find(x => x.id === importGroupId) : null;

    return (
        <>
            <Modal isOpen={!!importGroupId} toggle={() => setImportGroupId(null)}>
                <ModalHeader toggle={() => setImportGroupId(null)}>{"Betriebsmittelgruppe importieren"}</ModalHeader>
                <ModalBody>
                    {`Wollen sie die Betriebsmittelgruppe ${currImportGroup ? currImportGroup.name : ""} wirklich importieren?`}
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={importGroup}>Importieren</Button>{' '}
                    <Button color="secondary" onClick={() => setImportGroupId(null)}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
            <h4>{`Import${group ? ` (${group.name})` : ""}`}</h4>
            {group ? <ResourceGroupTabs
                roomData={props.roomData}
                onSelectTab={(tab) => props.redirectGroupId(groupId, tab, false, url)}
                group={group}
                saveData={(x) => { }}
                isReadonly={true} />
                :
                projects.map(proj => <ImportProjectEntry
                    key={proj.id}
                    project={proj}
                    onSelect={(id) => props.redirectGroupId(id, "resource", false, url)}
                    onClickImport={setImportGroupId}
                    groups={props.groups}
                />)
            }
        </>
    );
}
export default ImportPage;