import React, { useState, useEffect } from "react";
import authService from './AuthorizeService';
import { NavItem } from 'reactstrap';

function AuthorizeNavItem(props) {

    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);


    async function populateAuthenticationState() {
        let isAuth = false;
        if (props.role) {
            isAuth = await authService.isRole(props.role);
        }
        else {
            isAuth = await authService.isAuthenticated();
        }
        setReady(true);
        setAuthenticated(isAuth);
    }

    async function authenticationChanged() {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    }

    useEffect(() => {
        let _subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();
        return function cleanup() {
            authService.unsubscribe(_subscription);
        }
    }, []);

    if (!ready || !authenticated) {
        return <div></div>;
    } else {
        const { role, ...rest } = props;
        return <NavItem {...rest}/>
    }
}
export default AuthorizeNavItem;