import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import ProjectCard from "./ProjectCard";

function ProjectList(props) {
    const projCards = props.projects.map((proj) =>
        <Col className="p-3" sm="6" md="4" lg="3" key={proj.id}>
            <ProjectCard
                onClickCopy={() => props.onClickCopy(proj.id)}
                onClickEdit={() => props.onClickEdit(proj.id)}
                onClickDelete={() => props.onClickDelete(proj)}
                project={proj}
                copyMode={props.copyMode}
            />
        </Col>)
    return props.projects.length > 0 ? <Row>{projCards}</Row> : <p>Keine Projekte vorhanden, erstelle ein neues.</p>;
}
export default ProjectList;