import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import FormTextInput from './FormTextInput';

export default function ClientEditor(props) {
    return (
        <Formik
            initialValues={{ name: props.clientName, license: props.license }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .max(30, 'Darf maximal 30 Zeichen oder weniger sein')
                    .required('Benötigt'),
                license: Yup.string()
                    .required('Benötigt')
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                props.onClickSave(values.name, values.license);               
            }}
        >
            <Form>
                <h2>{props.clientName ? "Mandant bearbeiten" : "Neuer Mandant"}</h2>
                <div className="form-group">
                    <FormTextInput
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                    />
                </div>
                <div className="form-group">
                    <FormTextInput
                        label="Lizenz"
                        name="license"
                        type="text"
                        placeholder="Lizenz"
                    />
                </div>
                <div className="btn-toolbar">
                    <div className="btn-group mr-2">
                        <button type="submit" className="btn btn-primary">Speichern</button>
                    </div>
                    <div className="btn-group mr-2">
                        <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}