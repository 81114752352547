import React from "react";
import { Switch, Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { Row, Col, Button, Card, CardBody, CardHeader, UncontrolledCollapse } from 'reactstrap';
import { Formik, Field, Form, FieldArray, ErrorMessage, useField } from 'formik';
import { FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import FormTextInput from '../FormTextInput';
import FormCheckInput from '../FormCheckInput';
import TimeDurationInput from "../TimeDurationInput";

function CheckEditor(props) {
    const empty = "";

    let name = "";
    let isRequired = false;
    let options = [];
    let func = empty;
    let allowFreeInput = false;
    let normDescription = "";
    let interval = 0
    let intervalUnit = empty
    let duration = 0
    let durationUnit = empty
    let requiredQualification = "";

    let mainManagerName = "";
    let mainManagerEmail = "";
    let secondaryManagerName = "";
    let secondaryManagerEmail = "";

    let requestedInspectorName = "";
    let notificationEmail = "";

    let costCenter = "";
    let requireExternInspector = false;

    if (props.check) {
        name = props.check.name === null ? "" : props.check.name;
        isRequired = props.check.isRequired;
        options = props.check.options;
        func = props.check.function === null ? empty : props.check.function;
        allowFreeInput = props.check.allowFreeInput;
        normDescription = props.check.normDescription === null ? "" : props.check.normDescription;
        interval = props.check.interval;
        intervalUnit = props.check.intervalUnit === null ? empty : props.check.intervalUnit;
        duration = props.check.duration;
        durationUnit = props.check.durationUnit === null ? empty : props.check.durationUnit;
        requiredQualification = props.check.requiredQualification === null ? "" : props.check.requiredQualification;

        mainManagerName = props.check.mainManagerName ?? "";
        mainManagerEmail = props.check.mainManagerEmail ?? "";
        secondaryManagerName = props.check.secondaryManagerName ?? "";
        secondaryManagerEmail = props.check.secondaryManagerEmail ?? "";

        requestedInspectorName = props.check.requestedInspectorName ?? "";
        notificationEmail = props.check.notificationEmail ?? "";

        costCenter = props.check.costCenter ?? "";
        requireExternInspector = props.check.requireExternInspector ?? false;
    }

    return (
        <Formik
            initialValues={{
                name: name,
                isRequired: isRequired,
                options: options,
                function: func,
                allowFreeInput: allowFreeInput,
                file: null,
                fileRemoved: false,
                normDescription: normDescription,
                interval: interval,
                intervalUnit: intervalUnit,
                duration: duration,
                durationUnit: durationUnit,
                requiredQualification: requiredQualification,

                mainManagerName,
                mainManagerEmail,
                secondaryManagerName,
                secondaryManagerEmail,

                requestedInspectorName,
                notificationEmail,

                costCenter,
                requireExternInspector
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(3, 'Name muss mindestens 3 Zeichen lang sein!')
                    .max(300, 'Name muss 300 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                options: Yup.array()
                    .of(Yup.object().shape({
                        name: Yup.string()
                            .max(300, 'Name muss 300 Zeichen oder kürzer sein!')
                            .required('Benötigt')
                    })),
                mainManagerEmail: Yup.string().email('Eingabe ist keine korrekte Email'),
                secondaryManagerEmail: Yup.string().email('Eingabe ist keine korrekte Email'),
                notificationEmail: Yup.string().email('Eingabe ist keine korrekte Email'),
                costCenter: Yup.string().max(15, "Die Kostenstelle darf maximal 15 Zeichen lang sein")
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                let intUnit = parseInt(values.intervalUnit);
                let durUnit = parseInt(values.durationUnit);

                let jsonData = {
                    id: props.check && props.check.id,
                    name: values.name,
                    isRequired: values.isRequired,
                    options: values.options,
                    function: values.function == empty ? null : values.function,
                    allowFreeInput: values.allowFreeInput,
                    normDescription: values.normDescription,
                    interval: values.interval,
                    intervalUnit: isNaN(intUnit) ? null : intUnit,
                    duration: values.duration,
                    durationUnit: isNaN(durUnit) ? null : durUnit,
                    requiredQualification: values.requiredQualification,

                    mainManagerName: values.mainManagerName,
                    mainManagerEmail: values.mainManagerEmail,
                    secondaryManagerName: values.secondaryManagerName,
                    secondaryManagerEmail: values.secondaryManagerEmail,

                    requestedInspectorName: values.requestedInspectorName,
                    notificationEmail: values.notificationEmail,
                    costCenter: values.costCenter,
                    requireExternInspector: values.requireExternInspector
                }
                let fileData = new FormData();
                if (values.file)
                    fileData.append("file", values.file);

                //determine if filedata was changed
                let fileDataWasChanged = values.fileRemoved || values.file;

                props.onClickSave(jsonData, fileDataWasChanged, fileData);
            }}
            render={({ setFieldValue, values }) => (

                <Form className="mt-2">
                    <h5>{name ? "Check bearbeiten" : "Neuer Check"}</h5>
                    <Card className="mt-2">
                        <CardHeader>
                            <Button color="link" id="checkToggler">
                                Check
                            </Button>
                        </CardHeader>

                        <UncontrolledCollapse toggler="#checkToggler" defaultOpen>
                            <CardBody>
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="name"
                                            label="Bezeichnung"
                                            type="text"
                                            placeholder="Bezeichnung" />
                                    </Col>
                                </Row>
                                <br/>
                                <Row form>
                                    <Col md={6}>
                                        <TimeDurationInput
                                            label="Intervall"
                                            valueName="interval"
                                            scaleName="intervalUnit"
                                            emptyValue={empty}
                                            scales={["year", "month", "week", "day"]}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row form>
                                    <Col md={6}>
                                        <Label for="function">Checkart</Label>
                                        <Field id="function" as="select" className="form-control" name="function">
                                            <option value={empty}>Auswahl</option>
                                            <option value="Datum">Datum</option>
                                        </Field>
                                    </Col>
                                </Row>
                                
                                {values.function === empty ? (
                                    <FieldArray
                                        name="options"
                                        render={arrayHelpers => (
                                            <div>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <h6>Optionen</h6>
                                                    </Col>
                                                    <Col>
                                                        <Button outline color="success" className="float-right" onClick={() => arrayHelpers.push({ name: "", isFaulty: false, isDefault: false })}>
                                                            Option hinzufügen
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {values.options && values.options.length > 0 ? (
                                                    <div>
                                                        <Row>
                                                            <Col md={6}>
                                                                <b>Bezeichnung</b>
                                                            </Col>
                                                            <Col md={2}>
                                                                <b>Fehlerhaft</b>
                                                            </Col>
                                                            <Col md={2}>
                                                                <b>Standardwert</b>
                                                            </Col>
                                                        </Row>

                                                        {values.options.map((option, index) => (
                                                            <Row className="mt-2" form key={index}>
                                                                <Col md={6}>
                                                                    <FormTextInput hideLabel name={`options.${index}.name`} placeholder="Bezeichnung" />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <Field
                                                                        type="checkbox"
                                                                        name={`options.${index}.isFaulty`}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <Input type="checkbox" checked={option.isDefault} onChange={event => {
                                                                        values.options.forEach((x, idx) => setFieldValue(`options.${idx}.isDefault`, false))
                                                                        setFieldValue(`options.${index}.isDefault`, event.target.checked)
                                                                    }} />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <Button className="float-right" outline color="danger" onClick={() => arrayHelpers.remove(index)}>
                                                                        Löschen
                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                )
                                                    :
                                                    <p> Es sind noch keine Optionen vorhanden </p>
                                                }
                                                <FormGroup check inline>
                                                    <FormCheckInput
                                                        label="Freie Eingabe erlauben"
                                                        name="allowFreeInput"
                                                        type="checkbox"
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                    />)
                                    : null
                                }
                            </CardBody>
                        </UncontrolledCollapse>
                    </Card>
                    <Card className="mt-2">
                        <CardHeader>
                            <Button color="link" id="normToggler">
                                Norm
                            </Button>
                        </CardHeader>

                        <UncontrolledCollapse toggler="#normToggler">
                            <CardBody>
                                <Row form>
                                    <Col md={6}>
                                        <Label for="normDescription">Normverweis für Druck</Label>
                                        <Field as="textarea" className="form-control" type="textarea" name="normDescription" />
                                    </Col>
                                </Row>
                                {props.check && props.check.fileName && !values.fileRemoved ?
                                    <FormGroup>
                                        <Label for="removeFile">Datei für Zusatzinfo Erfassung</Label>
                                        <Col>
                                            <Button color="danger" name="removeFile" onClick={() => { setFieldValue("fileRemoved", true) }}>Datei löschen</Button> {' '}
                                            {props.check.id ? <Button color="primary" onClick={() => { props.onDownloadFile() }}>Datei herunterladen</Button> : null}
                                        </Col>
                                        <Col>
                                            <p>{props.check.fileName}</p>
                                        </Col>
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label for="fileSelect">Datei für Zusatzinfo Erfassung</Label>
                                        <Input type="file" name="file" accept=".pdf" onChange={(event) => { setFieldValue("file", event.currentTarget.files[0]) }} />
                                    </FormGroup>
                                }
                            </CardBody>
                        </UncontrolledCollapse>
                    </Card>
                    <Card className="mt-2">
                        <CardHeader>
                            <Button color="link" id="costToggler">
                                Kosten
                            </Button>
                        </CardHeader>

                        <UncontrolledCollapse toggler="#costToggler">
                            <CardBody>
                                <Row form>
                                    <Col md={6}>
                                        <TimeDurationInput
                                            label="Dauer"
                                            valueName="duration"
                                            scaleName="durationUnit"
                                            emptyValue={empty}
                                            scales={["day", "hour", "minute"]}
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="costCenter"
                                            label="Kostenstelle"
                                            type="text"
                                            placeholder="Kostenstelle" />
                                    </Col>
                                </Row>
                            </CardBody>
                        </UncontrolledCollapse>
                    </Card>
                    <Card className="mt-2">
                        <CardHeader>
                            <Button color="link" id="personToggler">
                                Personen
                            </Button>
                        </CardHeader>

                        <UncontrolledCollapse toggler="#personToggler">
                            <CardBody>
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="requestedInspectorName"
                                            label="Gewünschter Prüfer"
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="notificationEmail"
                                            label="Benachrichtigungsemail"
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </Col>
                                </Row>
                                <FormGroup check inline>
                                    <FormCheckInput
                                        label="Externer Prüfer"
                                        name="requireExternInspector"
                                        type="checkbox"
                                    />
                                </FormGroup>
                                <hr />
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="requiredQualification"
                                            label="Benötigte Qualifikation"
                                            type="text"
                                            placeholder="Qualifikation" />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="mainManagerName"
                                            label="Name Hauptverantwortlicher"
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="mainManagerEmail"
                                            label="Email Hauptverantwortlicher"
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="secondaryManagerName"
                                            label="Name stellvertretender Verantwortlicher"
                                            type="text"
                                            placeholder="Name"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormTextInput
                                            name="secondaryManagerEmail"
                                            label="Email stellvertretender Verantwortlicher"
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </UncontrolledCollapse>
                    </Card>
                    <div className="btn-toolbar mt-2">
                        <div className="btn-group mr-2">
                            <button type="submit" className="btn btn-primary">Speichern</button>
                        </div>
                        <div className="btn-group mr-2">
                            <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                        </div>
                    </div>
                </Form >
            )
            }
        />
    );
}
export default CheckEditor;