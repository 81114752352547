import React from 'react';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import FormTextInput from '../FormTextInput';

export default function RoomEditor(props) {
    let csId = "";
    let name = "";
    if (props.room) {
        csId = props.room.csId;
        name = props.room.name;
    }

    return (
        <Formik
            initialValues={{ csId: csId, name: name }}
            validationSchema={Yup.object({
                csId: Yup.string()
                    .min(3, 'ComSchäcke Id muss mindestens 3 Zeichen lang sein!')
                    .max(30, 'ComSchäcke Id muss 30 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
                name: Yup.string()
                    .min(3, 'Raumname muss mindestens 3 Zeichen lang sein!')
                    .max(300, 'Raumname muss 300 Zeichen oder kürzer sein!')
                    .required('Benötigt'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                props.onClickSave({ csId: values.csId, name: values.name });
            }}
        >
            <Form>
                <h3>{csId ? "Raum bearbeiten" : "Neuer Raum"}</h3>
                <div className="form-group">
                    <FormTextInput
                        label="ComSchäcke Id"
                        name="csId"
                        type="text"
                        placeholder="ComSchäcke Id"
                    />
                </div>
                <div className="form-group">
                    <FormTextInput
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                    />
                </div>
                <div className="btn-toolbar">
                    <div className="btn-group mr-2">
                        <button type="submit" className="btn btn-primary">Speichern</button>
                    </div>
                    <div className="btn-group mr-2">
                        <button type="button" onClick={props.onClickCancel} className="btn btn-secondary">Abbrechen</button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}